@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'SF';
  font-weight: 200;
	src: url('../assets/fonts/SFUIDisplay-Thin.woff') format('woff');
}

@font-face {
	font-family: 'SF';
  font-weight: 300;
	src: url('../assets/fonts/SFUIText-Light.woff') format('woff');
}

@font-face {
	font-family: 'SF';
  font-weight: 400;
	src: url('../assets/fonts/SFUIText-Regular.woff') format('woff');
}

@font-face {
	font-family: 'SF';
  font-weight: 500;
	src: url('../assets/fonts/SFUIText-Medium.woff') format('woff');
}

@font-face {
	font-family: 'SF';
  font-weight: 600;
	src: url('../assets/fonts/SFUIText-Semibold.woff') format('woff');
}

@font-face {
	font-family: 'SF';
  font-weight: 800;
	src: url('../assets/fonts/SFUIText-Heavy.woff') format('woff');
}

* {
	font-family: "SF", "Montserrat", "Arial", sans-serif;

	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input{
	font-weight: 400 !important;
}