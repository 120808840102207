.hww{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    width: max-content;
}

.hww__title{
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 30px;
}

.hww__roadmap{
    display: flex;
    column-gap: 70px;
    flex-wrap: wrap;
    justify-content: center;
}

.roadmap__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 200px;
}

.roadmap-title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}

.roadmap-img{
    width: 200px;
    height: 200px;
}

.roadmap-img-arrow{
    width: 50px;
}

.roadmap-subtitle{
    font-size: 12px;
    font-weight: 300;
}

@media (max-width: 900px) {
    .hww__roadmap{
        flex-direction: column;
        align-items: center;
        row-gap: 50px;
    }

    .roadmap-img-arrow{
        transform: rotate(90deg);
    }
}