.posts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-left: 40px;
    min-height: 250px;
}

.post-card{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 20px;
    box-shadow:  10px 10px 30px #e0e0e0;
    border-radius: 10px;
    align-self: center;
    width: 95%;
}

.post-content-button{
    width: 100%;
    padding: 10px 16px 10px 16px;
    border-radius: 8px;
    font-size: 14px;
    color: white;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8B5CF6;

    &:disabled{
        opacity: .75;
        pointer-events: none;
    }

    &:hover{
        background-color: #8f69e7;
    }

    &.mono{
        background-color: #f3f3f3;
        color: #8B5CF6;
        border: 1px solid #8B5CF6;

        &:hover{
            background-color: #8B5CF6;
            color: #eeeeee;
        }
    }

    &.danger{
        background-color: red;

        &:hover{
            background-color: rgba(206, 53, 53, 0.75);
        }
    }

}

.post-button{
    height: 44px;
    padding: 10px 16px 10px 16px;
    margin-top: 10px;
    border-radius: 8px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    border: 1px solid #D0D5DD;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8B5CF6;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    &.mono{
        background-color: #f3f3f3;
        color: #8B5CF6;
        border: 1px solid #8B5CF6;

        &:hover{
            background-color: #8B5CF6;
            color: #eeeeee;
        }
    }
}

.post-sn-container {
    margin-bottom: 15px;
}

.ant-drawer-content-wrapper{
    height: 95vh !important;
    height: 95dvh !important;
}