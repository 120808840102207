/* BottomNav.css */

.bottom-nav-content {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color: #6a5acd; !* Adjust the color to match your design *!*/
  background: linear-gradient(45deg, #9ad3f2 0%, #e4acf5 100%);
  color: #000;
    padding: 15px 10px;
  }
  
  .nav-item-content img {
    width: 30px; /* Adjust the size as needed */
    height: 30px; /* Adjust the size as needed */
    opacity: 0.7; /* Adjust the opacity as needed */
  }
  
  .nav-item-content {
  width: 60px;
  display: flex;
  justify-content: center;
  }
  
  .bottom-nav-indicator {
    position: absolute;
    bottom: 0;
    left: 50%; /* Adjust this value to position the indicator under the active tab */
    transform: translateX(-50%); /* Centers the indicator */
    height: 4px; /* Adjust thickness of the line */
    width: 60px; /* Adjust the width as needed */
    background-color: #fff; /* Adjust the color to match your design */
  }
  

  .social-network {
    width: 90%;
    margin: 0 auto;
    padding: 15px 20px;
    border-bottom: 1px solid grey;
    background-color: rgba(238, 235, 255, 1);
    border-radius: 5px;
  }

  .social-network-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

  }

  hr.social-network-divider{
    width: 100%;
    margin-top: 10px;
    height: 2px;
    background-color: rgba(174, 179, 190, 1);
    color: rgba(238, 235, 255, 1);
  }

  .items-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }



  /* ScheduleItem.css */
.schedule-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 96%;
  background-color: #FFFFFF;
  border-radius: 12px; /* Adjust for rounded corners */
  padding: 20px 20px 7px 20px;
  margin: 15px 0;
  /*font-family: Arial, sans-serif;*/
  /*box-shadow:  10px 10px 30px #e0e0e0;*/
  border: 1px solid rgba(102, 112, 133, 0.53);

}

.icon-placeholder {
  border-radius: 20px;
  margin-right: 10px;
  width: 40px;
}

.icon-placeholder img{
  width: auto;
}

.schedule-details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -30px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.schedule-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.schedule-badge,
.schedule-day,
.schedule-time,
.schedule-type {
  background-color: #f0f0ff; /* Light purple background */
  border-radius: 12px;
  padding: 2px 10px;
  font-size: 0.7em;
  margin-right: 5px;
  border: 1px solid #000;
  color: rgba(83, 56, 158, 1);
  background-color: rgba(244, 235, 255, 1);
}

.schedule-description {
  font-size: 14px;
  width: 50%;
}

.--schedule-red-badge{
  background-color: rgb(255, 125, 125);
  color: white;
}

.--schedule-green-badge{
  background-color: rgb(37, 184, 0);
  color: white;
}

.--schedule-yellow-badge{
  background-color: rgb(245, 161, 0);
  color: white;
}

.arrow-icon-placeholder {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

.refresh-icon-placeholder{
  /*width: 220px;*/
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: .5rem;
  border-radius: 12px;
  margin: 5px;
}

.refresh-icon-placeholder p{
  width: max-content;
  padding: 5px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.plan-card-button{
  width: min-content;
  min-width: min-content;
  font-size: 14px;
}

@media(max-width: 550px){
  .schedule-info{
    flex-direction: column;
  }

  .schedule-info:last-child div{
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  .schedule-description{
    width: 100%;
  }
}