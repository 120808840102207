.survey-page {
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    max-width: 375px;
    margin: auto;
  }
  
  
  .survey-page .menu-icon {
    /* Add styles for menu icon */
  }
  
  .survey-page .title {
    /* Add styles for title */
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 60px;
  }
  
  .survey-page .status-bar {
    /* Add styles for status bar */
  }
  
  .survey-page .question-carousel {
    /* Add styles for question carousel */
  }
  
  .survey-page .question-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Add additional styling */
  }
  
  .survey-page .question-navigation div {
    padding: 0 10px;
  }
  
  .survey-page .current-question {
    font-weight: bold;
  }
  
  .survey-page .question {
    text-align: center;
    padding: 20px;
    margin: 20px 0px;
  }
  
  .survey-page .mic-icon {
    /* Add styles for microphone icon */
    display: flex;
    justify-content: center;
  }
  
  .survey-page .answer-input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 20px;
    height: 100px; /* Adjust based on design */
  }
  
  .survey-page .buttons {
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }
  
  .survey-page .clear-button {
    /* Add styles for clear button */
  }
  
  .survey-page .submit-button {
    /* Add styles for submit button */
  }
  
  /* Add this to your existing SurveyPage.css file */

/* Text Area Styles */
.text-area-container{
  margin: 40px 16px; /* Spacing around the text area */
}
.text-area-container textarea {
  width: 100%;
  /*width: calc(100% - 32px); !* full width minus padding *!*/
  height: 150px; /* Adjust based on your design */
  padding: 16px; /* Padding inside the text area */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ccc; /* Light grey border */
  font-size: 16px; /* Font size */
  box-sizing: border-box; /* Makes sure padding doesn't add to the width */
}

/* Clear Button Styles */
.clear-button {
  width: 144px;
  background-color: #cccccc; /* Light grey background */
  color: #000000; /* Black text color */
  padding: 12px 24px;
  border: none;
  border-radius: 8px; /* Rounded corners */
  font-size: 16px; /* Font size */
  margin-right: 8px; /* Spacing between buttons */
  cursor: pointer; /* Cursor pointer on hover */
}
.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
/* Submit Button Styles */
.submit-button {
  width: 144px;
  background-color: #8A2BE2; /* Purple background color */
  color: #FFFFFF; /* White text color */
  padding: 12px 24px;
  border: none;
  border-radius: 8px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor pointer on hover */
}

/* Hover effects for buttons */
.clear-button:hover,
.submit-button:hover {
  opacity: 0.9; /* Slight opacity change on hover */
}
