.forgot-password-container {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    margin: auto;
    font-family: 'Arial', sans-serif;
  }
  
  .top-bar {
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: black;
  }
  
  .forgot-password-container .content {
    padding: 20px;
  }
  
  .forgot-password-container .back {
    font-size: 20px;
    cursor: pointer;
  }
  
  .forgot-password-container  h1 {
    text-align: center;
    font-size: 22px;
    margin: 20px 0;
  }
  
  .forgot-password-container p {
    text-align: center;
    color: #666;
    margin-bottom: 20px;
  }

  .forgot-password-container input {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .forgot-password-container button {
    width: 100%;
    border: 1px solid;
    border-image-source: linear-gradient(45deg, #6941C6 0%, #7F56D9 100%);
    background: linear-gradient(45deg, #6941C6 0%, #7F56D9 100%);
    box-shadow: 0px 1px 2px 0px #1018280F;
    box-shadow: 0px 1px 3px 0px #1018281A;
    border: 1px solid;
    color: #FFFFFF; /* White text color */
    border: none;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .forgot-password-container button:hover {
    background-color: #7f29e2; /* Slightly darker purple for hover effect */
  }
  
  
  
  