.header-container {
    max-width: 600px;
    display: flex;
    background-color: #FFFFFF; /* Assuming a white background */
    color: #000000; /* Assuming black text */
    /* position: relative; For absolute positioning of the back button */
    align-items: center;
    margin-left: 15px;
  margin-top: 15px;
  }

.header-container img{
  background: linear-gradient(45deg, #9ad3f2 0%, #e4acf5 100%);
  border-radius: 50%;
  aspect-ratio: 1;
}
  
  .back-button {
    width: 60px;
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px; /* Adjust the size as needed */
    line-height: 1; /* Adjust line-height to vertically center the back arrow */
  }
  
  .header-title {
    font-size: 17px; /* Font size for the title */
    font-weight: bold; /* Bold font weight for the title */
    color: #000000; /* Black color for the title text */
  }
  
  /* You might need to add additional styles for the status bar */
  