.profile{
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-button {
    height: 44px;
    padding: 10px 16px 10px 16px;
    border-radius: 8px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    border: 1px solid #D0D5DD;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8B5CF6;
  }

  .--danger{
    background-color: red;
  }

  .profile_info_title{
    font-weight: 600;
  }

  .profile_info_container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 20px;
    box-shadow:  10px 10px 30px #e0e0e0;
    width: 300px;
    border-radius: 10px;
    align-self: center;
  }