.home-page {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 375px;
  margin: auto;
}

.menu-icon {
}

.welcome-text {
  margin-top: 35px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
}

.premium-icon{
  display: inline-block;
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-left: 5px;
}

.privacy-link {
  color: #6c63ff;
  font-size: 12px;
}

.card {
  margin: 35px 16px;
  position: relative;
}

.card-container {
  display: flex;
  flex-direction: column;
}

.personal-brand-strategy img {
  max-width: fit-content;
}

.card {
  position: relative;
  height: 119px;
}

.card img {
  z-index: -1;
  border-radius: 15px;
  box-shadow: 10px 10px 30px #d6d6d6;
}

.card div {
  font-weight: 600;
  color: white;
  width: 350px;
  height: 150px;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card .home-menu-title {
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
}

.card div.home-subscription-counter {
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 12px;
  cursor: help;
}

.schedule-container {
  margin: 16px;
}

.schedule-item-home {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
  min-height: 80px;
}

.head-container {
  display: flex;
  justify-content: center;
}

.schedule-item-home img {
  position: absolute;
}

#logout-desktop {
  display: none;
}

#logout-mobile {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.facebook {
}

.instagram {
}

.schedule-time,
.schedule-label,
.schedule-description,
.schedule-action {
}

.create-post {
}

