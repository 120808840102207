/* ContentPlanSettings.css */

.content-plan-settings {
    /* ... */
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
    padding: 20px; /* Adjust as necessary */
    width: 100%;
    text-align: center;
  }
  
  .frequency-section {
    text-align: left;
    margin-top: 20px;
  }
  
  .frequency-range {
    width: 95%;
    /* Style as needed */
    accent-color: #7F56D9;
  }
  
  .frequency-value {
    /* Style as needed */
  }
  
  .elements-section {
    text-align: left;
    margin-top: 20px;
  }
  
  .elements-title {
    /* Style as needed */
    margin: 10px 5px;
  }

  .option-label {
    color: #667085;
  }
  
  .toggle-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .toggle-switch {
    /* Style as needed */
    background: grey;
    width: 36px;
    height: 20px;
    border-radius: 12px;
    padding: 2px;
    cursor: pointer;
    position: relative;
  }
  
  .toggle-knob {
    /* Style as needed */
    background: #FFFFFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: all 0.3s ease;
  }

  .toggle-switch.active {
    background-color: #7F56D9;
  }
  
  .toggle-switch.active .toggle-knob {
    transform: translateX(16px);
  }
  
  .input-field input {
    width: 100%;
    border: 1px solid grey;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
  }
  
  .generate-button {
    /* Style as needed */
    width: 100%;
    background: linear-gradient(45deg, #6941C6 0%, #7F56D9 100%);
    border-radius: 8px;
    padding: 12px 20px;
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    margin-top: 20px;
}

.logout-button {
  /* Style as needed */
  width: 100%;
  background: linear-gradient(45deg, #9ad3f2 0%, #e4acf5 100%);
  border-radius: 8px;
  padding: 12px 20px;
  color: #000;
  font-weight: 500;
  border: none;
  cursor: pointer;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  margin-top: 20px;

  &:hover {
    background: linear-gradient(45deg, #b1dbf3 0%, #ecc4f8 100%);
  }
}

  .support-button{
    width: 100%;
    border-radius: 8px;
    padding: 5px 12px;
    color: #ababab;
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      color: #7F56D9;
      text-decoration: underline;
    }
  }

.close-button {
  width: 100%;
    background: linear-gradient(45deg, grey 0%, grey 100%);
    border-radius: 8px;
    padding: 12px 20px;
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    margin-top: 5px;
}