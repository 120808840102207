.generate-post{
    margin-left: 35px;
}

.generate-post__description{
    border: 1px gray solid;
    resize: none;
    outline: none;
    border-radius: 10px;
    padding: 10px;
    width: 30%;
    height: 100px;
}

.post-info-form{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.post-info-form label{
    display: block;
    margin: 0 auto 15px;
}

.post-datepicker{
    width: fit-content;
    margin: 10px auto;
}