/* BottomNav.css */

.bottom-nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #6a5acd; /* Adjust the color to match your design */
    padding: 20px 10px;
    z-index: 1000;
  }
  
  .nav-item img {
    width: 24px; /* Adjust the size as needed */
    height: 24px; /* Adjust the size as needed */
    opacity: 0.7; /* Adjust the opacity as needed */
  }
  
  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  
  .bottom-nav-indicator {
    position: absolute;
    bottom: 0;
    left: 50%; /* Adjust this value to position the indicator under the active tab */
    transform: translateX(-50%); /* Centers the indicator */
    height: 4px; /* Adjust thickness of the line */
    width: 60px; /* Adjust the width as needed */
    background-color: #fff; /* Adjust the color to match your design */
  }
  