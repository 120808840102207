/* SideNav.css */

.side-nav {
    background-color: white; /* Adjust the color to match your design */
    color: white;
    border-right: 1px solid black;
    width: 250px; /* Adjust width as needed */
    height: 76%;/* Adjust height as needed */
    position: fixed; /* Side nav is fixed to the side of the screen */
    top: 0;
    bottom: 0;
    transform: translateX(-115%);
    height: calc(100vh - 85px);
    transition: transform 0.3s ease-in-out;
    overflow: auto; /* Scroll inside the nav if content is too long */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .side-nav.visible {
    transform: translateX(-9%);
  }
  
  .nav-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 40px; /* Give some space for the toggle button */
  }
  
  .nav-item {
    padding: 10px 15px;
    text-decoration: none;
    display: block;
    color: black;
    border-bottom: 1px solid black; /* Border color */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .nav-item:hover {
    background-color: #5f4bad; /* Slightly darker purple on hover */
  }
  
  .toggle-button {
    background: none;
    border: none;
    color: black;
    padding: 10px 15px;
    font-size: 1.25em;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 100%; /* Position it to the left of the side nav */
    transform: translateX(-100%);
  }

  .personal-brand-button {
    color: #FFFFFF; /* White text color */
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
    width: 90%;
  
    border: 1px solid;
    border-radius: 8px;
    background: linear-gradient(45deg, #6941C6 0%, #7F56D9 100%);
  }
  