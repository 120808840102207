.subscription-plans {
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  column-gap: 40px;
  margin-top: 60px;
}

.subscription-plan__plan-card {
  padding: 30px;
  background-color: #8b5cf6;
  border-radius: 10px;
  color: white;
  box-shadow: 20px 20px 60px #bebebe;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subscription-plan__plan-card.disabled{
  /*opacity: .75;*/
  /*filter: saturate(0%) brightness(150%);*/
  background-color: #e4e4e4;
  color: #848484;
  box-shadow: 20px 20px 60px rgba(190, 190, 190, 0.51);
}

.plan-card__title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: auto;
}

.plan-card__description {
  font-size: 15px;
  font-weight: 300;
}

.plan-card__price {
  position: relative;
  font-size: 35px;
  font-weight: 800;
  margin: 25px 0px;
  margin-bottom: auto;
}

.plan-card__price.--sale::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: #fd3535;
  border-radius: 5px;
  top: calc(50%);
  left: 0;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.53);
}

.sale-card__price {
  position: absolute;
  top: 25%;
  right: -60%;
  transform: rotate(12deg);
  background-color: red;
  border: 2px dashed white;
  padding: 5px 5px 5px 12px;
  border-radius: 10px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.32);
  font-weight: 800;
  font-size: 20px;
  z-index: 2;
  transform-origin: left;
  animation: mini-shake 7.5s ease-in-out infinite alternate;
}

@keyframes mini-shake {
  0%{
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(12deg);
  }
  50%{
    transform: rotate(-24deg);
  }
  75%{
    transform: rotate(15deg);
  }
  100%{
    transform: rotate(12deg);
  }
}

.sale-card__price::after {
  content: '';
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #000;
  border: 1px solid white;
  border-radius: 50%;
  top: calc(50%);
  left: 5px;
  transform: translateY(-50%);
}

.plan-card__button {
  height: 44px;
  padding: 25px 20px;
  border-radius: 8px;
  font-size: 19px;
  color: #8b5cf6;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  align-self: center;
  width: 100%;
  transition-duration: .3s;
}

.plan-card__button.--cancel{
  background-color: #8b5cf6;
  color: white;
  font-size: 16px;
}

.plan-card__button.--cancel:hover{
  background-color: rgba(255, 255, 255, 0.2);
}

.subscription-plan__plan-card.disabled .plan-card__button{
  color: #d4d4d4;
}

.plan-card__list {
  margin-top: 25px;
  list-style: disc;
  margin-left: 15px;
  text-align: center;
}

.--danger {
  color: red;
}

.--extend {
  color: #8b5cf6;
}

@media (max-width: 767px) {
  .subscription-plans {
    flex-direction: column;
  }

  .subscription-plan__plan-card {
    margin-bottom: 20px;
    width: 100%;
  }

  .survey-button {
    max-width: 100%;
  }
}
