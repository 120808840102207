/* SurveySelfAssessment.css */

.self-assessment {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }
  
  .self-assessment-title {
    /*color: #fff;*/
    /*background-color: rgba(114, 74, 206, 1);*/
    color: #000;
    background: linear-gradient(45deg, #9ad3f2 0%, #e4acf5 100%);
    font-size: 16px;
    text-align: left;
    padding: 15px 30px 15px 30px;
    margin-bottom: 30px;
    margin-top: 10px;
    font-weight: 600;
    text-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .self-assessment-content {
    margin-bottom: 50px;
    border: 2px solid purple;
    padding: 20px;
  }
  
  .self-assessment-section {
    margin-bottom: 20px;
  }
  
  .self-assessment-label {
    color: #9783FF;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .self-assessment-text {
    margin-top: 10px;
    margin-left: 10px;
  }

  .header-strategy-wrapper {
    height: 100px;
    width: 100%;
    position: relative;
  }

  .header-strategy {
    height: 100px;
    background: #fff;
    position: fixed;
    width: 100%;
  }

  .welcome-container {
    margin-top: 15px;
    margin-bottom: 0;
  }
  
  
  