.question-carousel {
  max-width: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.nav-button {
  background: none;
  border: none;
  color: #000;
  font-size: 60px;
  cursor: pointer;
}

.question-item {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 16px;
  color: #000;
  opacity: 0.5;
  transition: transform 0.3s ease, font-size 0.3s ease;
}

.question-item.hidden {
  visibility: hidden;
}

.question-item.active {
  font-weight: bold;
  opacity: 1;
  font-size: 18px; /* Slightly larger font size */
  transform: scale(1.4); /* Slightly larger scale */
  position: relative;
}

.question-item.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%; /* Adjust the width of the underline */
  height: 4px;
  background-color: #8A2BE2; /* The purple color for the active question underline */
}
