img{
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.text-primary{
  color: #6C63FF;
}
.bg-primary{
  background-color: #6C63FF;
}
.border-primary{
  border-color: #6C63FF;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}

.filter-bw{
  filter: saturate(0%);
}

.font-inherit{
  font: inherit;
}

.fade-in-from-left{
  animation: fadeInFromLeft .5s ease-in-out;
}

.fade-in-from-right{
  animation: fadeInFromRight .5s ease-in-out;
}

@keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
}
@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
}

select.no-arrows {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.no-arrows::-ms-expand {
  display: none;
}

.signup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
  max-width: 375px;
  margin: 0 auto;
}

.app-header {
  background-image: url("./pages/icons/background-idea.svg"); /* Placeholder for the background */
  background-size: contain;
  color: white;
  text-align: center;
  width: 376px;
  height: 140px;
  font-size: 2rem;
}

.signup-container {
  padding: 20px;
}

.signup-container h1 {
  color: #333;
  font-size: 20px;
  text-align: left;
}

.intro-text {
  text-align: left;
  margin-bottom: 30px;
  color: #344054;
  font-size: 12px;
}

.signup-form input {
  width: 100%;
  height: 44px;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px #1018280D;
  margin-top: 6px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 100;
}

.signup-form select {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px #1018280D;
  margin-top: 6px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 100;
}

.signup-form .input-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}

.password-requirements {
  color: #667085;
  font-size: 12px;
  margin-bottom: 25px;
}

.forgot-password {
  text-align: right;
  color: #667085;
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 20px;
}

.continue-button {
  width: 100%;
  background-color: #6c63ff; /* Placeholder for the button color */
  color: white;
  padding: 15px;
  border: none;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 1rem;
}

.signin-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.signin-link .link {
  color: #6c63ff;
  cursor: pointer;
}

.divider {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider-line {
  flex: 1;
  height: 1px;
  background-color: #ddd; /* Use the color that matches your design */
}

.divider-text {
  width: 30px; /* Adjust the width as necessary */
  font-size: 16px;
  color: #98A2B3; /* Use the color that matches your design */
  margin: 0 10px;
}

.keyboard-shortcut-info kbd {

}

.social-login-buttons {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.social-button {
  height: 65px;
  padding: 10px 16px 10px 16px;
  margin-bottom: 10px;
  border-radius: 8px;
  font-size: 14px;
  color: #344054;
  text-align: center;
  cursor: pointer;
  border: 1px solid #D0D5DD;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s ease-in-out all;
}

.social-button:disabled{
  /*background-color: #7DE667;*/
  cursor: auto;
  /*border: none;*/
  opacity: .5;
}

.social-button .icon {
  margin-right: 10px;
}

.welcome-container {
  background-color: #FFFFFF; /* White background */
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
}

.main-content {
  text-align: left;
  padding: 0 16px;
}

.main-content h1 {
  color: #000000; /* Black color for the heading */
  font-size: 20px;
  margin-bottom: 8px;
  margin-bottom: 40px;
}

.main-content p {
  color: #000000; /* Gray color for the paragraph */
  font-size: 16px;
  margin-bottom: 40px;
}

.survey-button {
  color: #FFFFFF; /* White text color */
  padding: 12px 20px 12px 20px;
  font-size: 16px;
  font-weight: 600;
  width: 345px;
  height: 48px;

  border: 1px solid;
  border-radius: 8px;
  border-image-source: linear-gradient(45deg, #6941C6 0%, #7F56D9 100%);
  box-shadow: 0px 1px 2px 0px #1018280F;
  box-shadow: 0px 1px 3px 0px #1018281A;  
  background: linear-gradient(45deg, #6941C6 0%, #7F56D9 100%);
}

.footer {
  height: 5px;
  background-color: #000000; /* Black footer line */
}

.privacy-checkbox-container{
  display: flex;
  font-size: 12px;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 15px;
}

.privacy-checkbox-container input{
  width: 20px;
  margin: 0;
}
